<template>
  <div>
    <loader v-show="isLoading"></loader>
    <navbar :links="navLinks">
      <template v-slot:navbar>
        <b-navbar id="mainNavbar" toggleable="lg" fixed="top">
          <b-navbar-brand class="px-3">
            <img src="@/assets/img/rbis_logo.svg" />
          </b-navbar-brand>

          <b-navbar-nav class="border-left px-3 eu-logo">
            <b-nav-item class="">
              <img src="@/assets/img/eu_logo.svg" />
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item class="font-weight-bold border-right agro-title">
              AgroData
            </b-nav-item>

            <select v-model="locale">
              <option v-for="(lang, i) in languageArray" :key="`lang${i}`" :value="lang">
                {{ lang.toUpperCase() }}
              </option>
            </select>

            <b-nav-form class="ml-3">
              <b-button variant="outline-primary" type="button" @click="backToPortal">
                <i class="fa fa-arrow-left" /> {{ $t('back_to_rbis_portal') }}
              </b-button>
            </b-nav-form>
          </b-navbar-nav>
        </b-navbar>
      </template>

      <template v-slot:content>
        <vue-page-transition name="fade-in-right">
          <router-view :key="$route.path" />
        </vue-page-transition>
      </template>
    </navbar>
  </div>
</template>

<style lang="scss">
@import "assets/scss/default-theme";
</style>

<script>
import azMessages from '@/data/dx.messages.az.json';

import { locale, loadMessages } from 'devextreme/localization';

import Navbar from "@/components/Navbar.vue";
import Loader from "@/components/Loader.vue";
import Api from "@/api";

export default {
  name: "App",
  components: {
    Navbar,
    Loader
  },
  data: () => ({
    isLoading: false,
    navLinks: [],
    languageArray: ['en', 'az'],
    locale: null
  }),
  created() {
    loadMessages(azMessages);

    this.locale = localStorage.getItem("locale") ?? "en";

    this.loadMenu();

    this.$eventHub.$on("error", (err) => {
      console.log("Error", err);
    });
  },
  methods: {
    backToPortal() {
      location.href = process.env.VUE_APP_PORTAL_URL;
    },

    loadMenu() {
      this.isLoading = true;

      Api.getMenu()
        .then((response) => this.navLinks = response)
        .catch((error) => this.$eventHub.emit(error))
        .finally(() => this.isLoading = false);
    }
  },
  watch: {
    "locale": {
      handler(value) {
        this.$i18n.locale = value;
        localStorage.setItem("locale", value);
        this.$eventHub.$emit("localeChanged");

        this.loadMenu();

        locale(this.locale);
      }
    },
  }
};
</script>