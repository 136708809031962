import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/scss/app.scss'

import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"

import VueSlimScroll from 'vue-slimscroll'
Vue.use(VueSlimScroll)

import Layout from "./components/Layout.vue"
Vue.component('layout', Layout)

import 'devextreme/dist/css/dx.light.css';
import './assets/css/dx.generic.custom-scheme.css';

import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";

Maps(Highcharts);
Vue.use(HighchartsVue);

import VuePageTransition from 'vue-page-transition'
import i18n from './i18n'
Vue.use(VuePageTransition)

Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')