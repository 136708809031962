<template>
  <div>
    <b-breadcrumb :items="breadcrumb"></b-breadcrumb>

    <slot />
  </div>
</template>

<script>
export default {
  props: ["breadcrumb"]
}
</script>