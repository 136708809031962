<template>
  <div>
    <nav id="sidebar" class="my-sidebar default-theme" :class="[collapse ? 'sidebar-visible' : 'sidebar-hidden']">
      <div class="sidebar-header">
        <HamburgerButton class="sidebar-button" :is-hamburger="!collapse" :class="[collapse ? 'visible' : 'hidden']"
          :disabled="canCollapse" theme="my-custom-theme" @click="onButtonClick" />
      </div>

      <div class="sidebar-nav" v-slimscroll>
        <ul class="nav flex-column">
          <li class="nav-item" @click="navlinkclicked" v-b-toggle.nav-accordion>
            <a href="/home" class="nav-link">
              <img src="@/assets/img/menu1.svg" />
              <span>{{ $t('homePage') }}</span>
            </a>
            <!-- <router-link class="nav-link" :to="{ name: 'home' }">
              <img src="@/assets/img/menu1.svg" />
              <span>{{ $t('homePage') }}</span>
            </router-link> -->
          </li>
          <template v-for="(link, index) in links">
            <template v-if="link.children === null">
              <li class="nav-item" :key="index" @click="navlinkclicked" v-b-toggle.nav-accordion>
                <router-link class="nav-link" :to="{ name: 'page', params: { id: link.id } }">
                  <img :src="getImgUrl(link.icon)" />
                  <span>{{ link.title }}</span>
                </router-link>
              </li>
            </template>
            <template v-else>
              <li class="nav-item" v-if="collapse" :key="index">
                <a class="nav-link" v-b-toggle="`collapse-${index}`">
                  <img :src="getImgUrl(link.icon)" />
                  <span>{{ link.title }}</span>
                </a>

                <b-collapse :id="`collapse-${index}`" accordion="nav-accordion">
                  <b-card>
                    <ul class="list-group">
                      <li class="list-group-item" v-for="(child, idx) in link.children" :key="idx">
                        <router-link :to="{ name: 'page', params: { id: child.id } }">
                          {{ child.title }}
                        </router-link>
                      </li>
                    </ul>
                  </b-card>
                </b-collapse>
              </li>
              <li class="nav-item" v-if="!collapse" :key="index">

                <a class="nav-link" :id="`collapse-${index}`">
                  <img :src="getImgUrl(link.icon)" />
                  <span>{{ link.title }}</span>
                </a>
                <b-popover class="nav-item-popover" :target="`collapse-${index}`" triggers="hover" placement="right">
                  <template #title></template>
                  <ul class="list-group">
                    <li class="list-group-item" v-for="(child, idx) in link.children" :key="idx">
                      <router-link :to="{ name: 'page', params: { id: child.id } }">
                        {{ child.title }}
                      </router-link>
                    </li>
                  </ul>
                </b-popover>
                <!-- <b-dropdown block variant="primary" class="m-2" dropright :no-caret="!collapse"
                  :class="{ active: isMenuActive(link.children, index) }">
                  <template #button-content>
                    <img :src="getImgUrl(link.icon)" />
                  </template>
                  <b-dropdown-item v-for="(child, idx) in link.children" :key="idx"
                    :to="{ name: 'page', params: { id: child.id } }">
                    {{ child.title }}
                  </b-dropdown-item>
                </b-dropdown> -->
              </li>
            </template>
          </template>
        </ul>
      </div>
    </nav>
    <div id="navbar" :class="[collapse ? 'sidebar' : 'no-sidebar']">
      <slot name="navbar" />
    </div>
    <!-- Hamburger Menu -->
    <div id="content" :class="[collapse ? 'sidebar' : 'no-sidebar']">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import HamburgerButton from "@jurajkavka/vue-hamburger-button";

export default {
  components: {
    HamburgerButton,
  },
  props: { links: Array },
  data: () => ({
    collapse: false,
    canCollapse: true,
    currentRouteName: "home",
  }),
  mounted() {
    if (window.innerWidth < 576) {
      this.collapse = false;
      this.canCollapse = false;
    }
  },
  methods: {
    onButtonClick() {
      if (this.canCollapse) {
        this.collapse = !this.collapse;
      }
    },

    getImgUrl(img) {
      if (img)
        return `data:image/png;base64,${img}`
      else
        return require("../assets/img/menu1.svg");
    },

    navlinkclicked() {
      //this.$root.$emit("bv::toggle::collapse", "collapse-2");
    },

    isMenuActive(links, index) {
      let id = this.$route.params.id;

      if (!id) return false;

      var activeLinks = links.filter(function (elem) {
        if (elem.id === id) return elem;
      });

      return activeLinks.length > 0;
    },
  },
  watch: {
    $route(to, from) {
      this.currentRouteName = to.name;
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/@jurajkavka/vue-hamburger-button/src/scss/default-theme.scss";

.my-custom-theme {
  @extend .default-theme;

  .bar1,
  .bar2,
  .bar3 {
    background-color: #fff;
  }
}
</style>