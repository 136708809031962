const requestInterceptor = config => {
	var accessToken = localStorage.getItem("access_token");
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}

	var locale = localStorage.getItem("locale");
	if (locale) {
		config.headers["Accept-Language"] = locale;
	}

	return config;
}

const errorInterceptor = error => {
	const { response } = error;

	let message = "";

	switch (response.status) {
		case 400:
			message = response.data.messages[0];
			break;

		case 401:
			location.href = "/";
			break;

		case 404:
			message = "404";
			break;

		case 500:
			message = "error.message";
			console.error(error.response.status, error.message);
			break;

		default:
			//router.push("/error");
			break;
	}

	return Promise.reject(message || "Error occured!");
}

const responseInterceptor = response => {
	switch (response.status) {
		case 200:
			break;
		default:
	}

	return Promise.resolve(response.data);
}

export { requestInterceptor, errorInterceptor, responseInterceptor }