import httpClient from "./httpClient/api";

export default {
  getData: (url) => httpClient.get(url),
  getMenu: () => httpClient.get("/api/menu"),
  getMenuById: (id) => httpClient.get(`/api/menu/${id}`),
  getViewConfig: (viewId) => httpClient.get(`/api/viewConfig/${viewId}`),
  getDataGridData: (viewId, params) => httpClient.get(`/api/data/dataGrid/${viewId}${params}`),
  getPivotGridData: (viewId) => httpClient.get(`/api/data/pivotGrid/${viewId}`),
  getChartData: (viewId, filterString) => httpClient.get(`/api/data/chart/${viewId}?${filterString}`),
  getMapData: (viewId, filterString) => httpClient.get(`/api/data/map/${viewId}?${filterString}`)
}